.wiggle {
  --wiggle-angle: calc(1.5deg * 400 / var(--inner-width));
  animation: wiggle 0.5s infinite linear;
}

@keyframes wiggle {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(var(--wiggle-angle)) translateX(1px) translateY(1px);
  }
  75% {
    transform: rotate(calc(var(--wiggle-angle) * -1)) translateX(1px) translateY(1px);
  }
  100% {
    transform: rotate(0);
  }
}

.beacon {
  animation: beacon 2s infinite ease-out;
}

@keyframes beacon {
  0% {
    box-shadow: 0 0 0 theme('spacing.0') var(--beacon);
  }
  100% {
    box-shadow: 0 0 0 theme('spacing.3') transparent;
  }
}

.textPageContent:not(.ios) *[data-ios] {
  @apply hidden;
}

.textPageContent.ios *[data-android] {
  @apply hidden;
}

.textPageContent ul {
  @apply flex flex-col gap-2.5 list-disc marker:text-accent;
  padding-inline-start: theme('spacing.5');
}

.textPageContent p,
.textPageContent li {
  @apply text-lg text-fg-3 text-justify leading-relaxed;
}

.textPageContent li ul {
  @apply mt-2.5;
}

.textPageContent h2 {
  @apply text-3lg font-bold mt-5;
}

.textPageContent h3 {
  @apply text-2lg font-bold mt-4;
}

.textPageContent h4 {
  @apply font-bold mt-2;
}

.textPageContent a {
  @apply font-semibold underline decoration-accent bg-gradient bg-clip-text text-transparent;
}

.textPageContent a:hover {
  @apply hover:underline;
}

.textPageContent strong {
  @apply font-semibold text-fg-max;
}

.textPageContent em {
  @apply italic;
}

.textPageContent span[data-branded] {
  @apply font-black bg-gradient bg-clip-text text-transparent;
}

.textPageContent code {
  @apply px-2 py-0.5 rounded-lg text-[calc(1rem*15/16)] whitespace-nowrap tracking-normal text-amber-900 bg-amber-100/70 shadow-[inset_0_0_4px_0_var(--tw-shadow-color)] shadow-amber-200/70;
  @apply dark:text-amber-200 dark:bg-bg-3 dark:shadow-none;
}
